import PropTypes from "prop-types"
import * as React from "react"
import BackToTopButton from "./buttons/back_to_top_button"
import ChatBotEN from "./chatbot/en"
import ChatBotPT from "./chatbot/pt"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"
import "./typography.css"

const Layout = ({ children, language, pathname, hasGlobeBg }) => {
  return (
    <>
      <Header language={language} pathname={pathname} />
      <div className="lg">
        <div
          className={hasGlobeBg ? "layout-globe container min-vh-100" : ""}
        ></div>
        <main>{children}</main>
        {language === "pt" ? <ChatBotPT /> : null}
        {language === "en" ? <ChatBotEN /> : null}
        <BackToTopButton />
      </div>
      <Footer language={language} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.oneOf(["pt", "en"]),
  pathname: PropTypes.string.isRequired,
  hasGlobeBg: PropTypes.bool,
}

Layout.defaultProps = {
  hasGlobeBg: false,
}

export default Layout
