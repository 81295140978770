import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import * as React from "react"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { ptArray, enArray } from "./array"
import "./header.css"

const Header = ({ language, pathname }) => {
  return (
    <Navbar fixed="top" id="headerNav" className="w-100" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to={language === "pt" ? "/" : "/en"}>
          <StaticImage
            src="../../assets/images/logo.png"
            placeholder="blurred"
            width={168}
            alt="CAMARADES-BR"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="w-100" fill>
            {language === "pt"
              ? ptArray.map(el => (
                  <Nav.Link
                    key={el.path}
                    as={Link}
                    to={el.path}
                    className={el.path === pathname ? "active" : ""}
                  >
                    {el.name}
                  </Nav.Link>
                ))
              : null}
            {language === "en"
              ? enArray.map(el => (
                  <Nav.Link
                    key={el.path}
                    as={Link}
                    to={el.path}
                    className={el.path === pathname ? "active" : ""}
                  >
                    {el.name}
                  </Nav.Link>
                ))
              : null}
            {language === "pt" ? (
              <NavDropdown
                title={
                  <StaticImage
                    src={"../../assets/images/flags/bra.png"}
                    className="m-0"
                    alt="flag-bra"
                  />
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  className="d-flex justify-content-around"
                  to="/en"
                >
                  <StaticImage
                    src={"../../assets/images/flags/usa.png"}
                    className="m-0"
                    alt="English (US)"
                  />
                  English
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
            {language === "en" ? (
              <NavDropdown
                title={
                  <StaticImage
                    src={"../../assets/images/flags/usa.png"}
                    className="m-0"
                    alt="flag-usa"
                  />
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  className="d-flex justify-content-around"
                  to="/"
                >
                  <StaticImage
                    src={"../../assets/images/flags/bra.png"}
                    className="m-0"
                    alt="Português (BR)"
                  />
                  Português
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  language: PropTypes.oneOf(["pt", "en"]),
}

Header.defaultProps = {
  siteTitle: ``,
  language: `pt`,
}

export default Header
