import React from "react"
import { Button } from "react-bootstrap"
import "../buttons.css"

const BackToTopButton = () => {
  return (
    <a href="#top">
      <Button className="back-to-top-btn">
        <span className="text-white h1">↑</span>
      </Button>
    </a>
  )
}

export default BackToTopButton
