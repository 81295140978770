import React from "react"
import ChatBot from "react-simple-chatbot"
import ChatBotLinkBtn from "./link_button"
import avatar from '../../assets/images/chatbot/avatar.jpeg'

const Colaboration = () => (
  <div>
    <a
      href="mailto:camaradesbr@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Email 📧
    </a>{" "}
    <br />
    <a
      href="https://join.slack.com/t/camaradesbr/shared_invite/zt-12f82wifb-4F6Nx6rs2NLi2XuJzetPpw"
      target="_blank"
      rel="noopener noreferrer"
    >
      Comunidade Slack 📝
    </a>
  </div>
)

const PartnersCamaradesBR = () => (
  <div>
    <a
      href="https://www.camarades.de"
      target="_blank"
      rel="noopener noreferrer"
    >
      CAMARADES Alemanha (Berlim)
    </a>{" "}
    <br />
    <a href="https://ed.ac.uk" target="_blank" rel="noopener noreferrer">
      CAMARADES Reino Unido (Edimburgo, Escócia){" "}
    </a>
  </div>
)
const AllCamarades = () => (
  <div>
    <a
      href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/our-partners"
      target="_blank"
      rel="noopener noreferrer"
    >
      Lista em CAMARADES Reino Unido (Edimburgo, Escócia)
    </a>
  </div>
)

const CamaradesHistory = () => (
  <div>
    <a
      href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/about-camarades"
      target="_blank"
      rel="noopener noreferrer"
    >
      Histórico em CAMARADES Reino Unido (Edimburgo, Escócia)
    </a>
  </div>
)

export default function ChatBotPT() {
  return (
    <ChatBot
      hideSubmitButton
      placeholder=""
      botAvatar={avatar}
      floatingStyle={{ backgroundColor: '#07BCFF' }}
      bubbleStyle={{ backgroundColor: 'blue', maxWidth: '80%', color: 'white' }}
      bubbleOptionStyle={{ backgroundColor: '#008ECB', color: 'white' }}
      floating
      headerTitle="Sys da CAMARADES BR"
      steps={[
        {
          id: "index",
          message:
            "Olá, sou a Sys e serei sua guia no site da CAMARADES BR. Sobre qual assunto você gostaria de saber mais? 😀",
          trigger: "index-options",
        },
        {
          id: "index-options",
          options: [
            {
              value: 1,
              label: "Sobre a CAMARADES",
              trigger: "about-camarades-options",
            },
            {
              value: 2,
              label: "Sobre Revisão Sistemática de Literatura",
              trigger: "about-slr",
            },
          ],
        },
        {
          id: "about-slr",
          message: `A RSL é uma metodologia para localizar, selecionar, avaliar e sintetizar de forma SISTEMÁTICA as evidências da literatura científica, fornecendo dados para meta-análises. A RSL é usada para responder uma pergunta científica definida por critérios pré-definidos e especificados de forma TRANSPARENTE!`,
          trigger: "about-slr-options",
        },
        {
          id: "about-slr-options",
          options: [
            {
              value: 1,
              label:
                "Existem ferramentas de RSL específicas para farmacologistas?",
              trigger: "slr-pharm-tools",
            },
            {
              value: 2,
              label:
                "E se o campo de estudo não for a Farmacologia, existem ferramentas de RSL genéricas que poderiam ser usadas em estudos não clínicos em outras áreas de ciências da vida?",
              trigger: "slr-generic-tools",
            },
            {
              value: 3,
              label: "Uma RSL sempre será acompanhada de uma meta-análise?",
              trigger: "slr-meta-analysis",
            },
            { value: "go-back", label: "Voltar", trigger: "index-options" },
          ],
        },
        {
          id: "slr-pharm-tools",
          message: `Sim! Existem ferramentas que podem ser adaptadas para se fazer uma RSL no contexto da Farmacologia. Veja aqui um tutorial que a CAMARADES BR preparou para farmacologistas.`,
          trigger: "slr-pharm-tools-options",
        },
        {
          id: "slr-pharm-tools-options",
          options: [
            { value: 1, label: "SysPhar", trigger: "sysphar" },
            { value: "go-back", label: "Voltar", trigger: "about-slr" },
          ],
        },
        {
          id: "sysphar",
          message: `SysPhar, de Systematic Pharmacology, é uma iniciativa da CAMARADES BR para apoiar RSL no campo da Farmacologia.`,
          trigger: "sysphar-options",
        },
        {
          id: "sysphar-options",
          options: [
            { value: 1, label: "Confira o SysPhar", trigger: "go-to-sysphar" },
            { value: "go-back", label: "Voltar", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-sysphar",
          component: <ChatBotLinkBtn to="/sysphar">SysPhar</ChatBotLinkBtn>,
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "slr-generic-tools",
          message: `Sim! Existem ferramentas para auxiliar em sua RSL no contexto de estudos não  clínicos em geral. Veja aqui um tutorial que o CAMARADES BR traduziu para língua Portuguesa a partir do site CAMARADES Berlin.`,
          trigger: "slr-generic-tools-options",
        },
        {
          id: "slr-generic-tools-options",
          options: [
            { value: 1, label: "Conferir o Wiki", trigger: "go-to-wiki" },
            { value: "go-back", label: "Voltar", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-wiki",
          component: <ChatBotLinkBtn to="/wiki">Wiki</ChatBotLinkBtn>,
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "slr-meta-analysis",
          message: `Não necessariamente, sua RSL pode ou não ser acompanhada de uma meta-análise, depende de sua pergunta de revisão! Veja exemplos em nossos tutorias:`,
          trigger: "slr-meta-analysis-options",
        },
        {
          id: "slr-meta-analysis-options",
          options: [
            {
              value: 1,
              label: "Conferir exemplos de meta-análise no SysPhar",
              trigger: "go-to-sysphar-meta-analysis",
            },
            {
              value: 2,
              label: "Conferir exemplos de meta-análise no Wiki",
              trigger: "go-to-wiki-meta-analysis",
            },
            { value: "go-back", label: "Voltar", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-sysphar-meta-analysis",
          component: (
            <ChatBotLinkBtn to="/sysphar">
              Exemplos de meta-análise no SysPhar
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "go-to-wiki-meta-analysis",
          component: (
            <ChatBotLinkBtn to="/wiki">
              Exemplos de meta-análise no Wiki
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-slr",
        },
        // START D2
        {
          id: "about-camarades-options",
          options: [
            {
              value: 1,
              label: "Sobre a CAMARADES no Brasil",
              trigger: "about-camarades-br",
            },
            {
              value: 2,
              label: "Sobre a CAMARADES no mundo",
              trigger: "about-camarades-world",
            },
            { value: "go-back", label: "Voltar", trigger: "index-options" },
          ],
        },
        {
          id: "about-camarades-br",
          message: `A CAMARADES é uma organização
                    sem fins lucrativos, fruto da
                    colaboração entre cientistas, para
                    promover a revisão sistemática da
                    literatura (RSL) de estudos não
                    clínicos na área de saúde.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "about-camarades-br-options",
          options: [
            {
              value: 1,
              label: "Quem apoia?",
              trigger: "about-camarades-br-supporters",
            },
            {
              value: 2,
              label: "Qual é a visão da colaboração?",
              trigger: "camarades-br-aim",
            },
            {
              value: 3,
              label: "O que esta colaboração faz?",
              trigger: "camarades-br-projects",
            },
            {
              value: 4,
              label: "Qual a missão da CAMARADES BR?",
              trigger: "camarades-br-mission",
            },
            {
              value: 5,
              label: "Quero colaborar. Como faço?",
              trigger: "camarades-br-colab",
            },
            {
              value: "go-back",
              label: "Voltar",
              trigger: "about-camarades-options",
            },
          ],
        },
        {
          id: "about-camarades-br-supporters",
          message: `Os projetos da CAMARADES BR
                    tem recebido apoio de agências
                    públicas de fomento científico
                    como a Fundação Alexander von
                    Humboldt, Capes e CNPq.`,
          trigger: "go-to-camarades-br-supporters",
        },
        {
          id: "go-to-camarades-br-supporters",
          component: (
            <ChatBotLinkBtn to="/sobre-a-camarades#apoiadores">
              Apoiadores
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-aim",
          message: `Um mundo em que os estudos não
                    clínicos tenham valor, validade e o
                    impacto positivo no
                    desenvolvimento científico na área
                    de saúde.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-projects",
          message: `Temos vários projetos em
                    andamento desde a criação de
                    material instrucional em português,
                    disponibilizado neste site, até
                    organização de oficinas de
                    treinamento, de acordo com
                    disponibilidade de agenda.`,
          trigger: "go-to-camarades-br-projects",
        },
        {
          id: "go-to-camarades-br-projects",
          component: (
            <ChatBotLinkBtn to="/portfolio-e-iniciativas">
              Projetos em Andamento
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-mission",
          message: `Ajudar a promover, na comunidade
                    científica
                    brasileira, os benefícios da RSL de
                    estudos não
                    clínicos em ciências da vida.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-colab",
          message: `Você pode colaborar! Como? Por
                    exemplo:
                    1-propondo uma nova RSL ou
                    participando de uma
                    existente,
                    2-produzindo material para o site
                    ou ajudando a
                    manter o Wiki atualizado,
                    3-ajudando a organizar uma oficina
                    de treinamento
                    4-participando das discussões da
                    comunidade.
                    Aguardamos seu contato!`,
          trigger: "camarades-br-colab-options",
        },
        {
          id: "camarades-br-colab-options",
          message: `Nos conte sobre seus interesses,
                    entre em
                    contato pelo E-mail.
                    Ou faça parte
                    de nossa comunidade no Slack:`,
          trigger: "go-to-camarades-br-colab-options-btns",
        },
        {
          id: "go-to-camarades-br-colab-options-btns",
          component: <Colaboration />,
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        // START D3
        {
          id: "about-camarades-world",
          message: `Grupo de pesquisa criado em 2004
                    por Malcolm Macleod e
                    David Howells para promover o
                    uso das revisões sistemáticas e
                    meta-análises de estudos não
                    clínicos e pré-clínicos na área de
                    saúde.`,
          trigger: "about-camarades-world-options",
        },
        {
          id: "about-camarades-world-options",
          options: [
            {
              value: 1,
              label: "O que significa a sigla da CAMARADES?",
              trigger: "camarades-initials",
            },
            {
              value: 2,
              label: "Além do Brasil, existem CAMARADES em outros países?",
              trigger: "other-camarades",
            },
            {
              value: 3,
              label:
                "Como posso saber mais sobre o histórico do CAMARADES fora do Brasil?",
              trigger: "camarades-history",
            },
            {
              value: "go-back",
              label: "Voltar",
              trigger: "about-camarades-options",
            },
          ],
        },
        {
          id: "camarades-initials",
          message: `Uma ótima pergunta!

                    CAMARADES vem do inglês para
                    “Collaborative
                    Approach to Meta-Analysis and
                    Review of Animal
                    Experimental Studies”.
                    
                    Poderia ser traduzida para o
                    português como “Colaboração
                    para revisar e sintetizar estudos
                    experimentais em animais
                    de laboratório”.`,
          trigger: "about-camarades-world-options",
        },
        {
          id: "other-camarades",
          message:
            "Sim, existem CAMARADES em vários países! Nossas parcerias diretas estão na Alemanha e no Reino Unido.",
          trigger: "go-to-partners-camarades-br",
        },
        {
          id: "go-to-partners-camarades-br",
          component: <PartnersCamaradesBR />,
          trigger: "all-camarades",
        },
        {
          id: "all-camarades",
          message:
            "Você pode conferir a lista dos centros nacionais mundo afora",
          trigger: "go-to-all-camarades",
        },
        {
          id: "go-to-all-camarades",
          component: <AllCamarades />,
          trigger: "about-camarades-world-options",
        },
        {
          id: "camarades-history",
          message: "Confira o histórico da CAMARADES no link a seguir",
          trigger: "go-to-camarades-history",
        },
        {
          id: "go-to-camarades-history",
          component: <CamaradesHistory />,
          trigger: "about-camarades-world-options",
        },
      ]}
    />
  )
}
