import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

const ChatBotLinkBtn = ({ to, children }) => {
  return <Link to={to}>{children} 🔗</Link>
}

ChatBotLinkBtn.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ChatBotLinkBtn.defaultProps = {
  to: "/",
}

export default ChatBotLinkBtn
