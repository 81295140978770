import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, article, lang, noRobots }) => {
  const { site } = useStaticQuery(query)

  const { defaultTitle, defaultDescription, defaultImage, siteUrl, keywords, author } =
    site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    keywords,
    author
    // url: `${siteUrl}${pathname}`,
  }
  return (
    <Helmet title={seo.title} titleTemplate={"%s | CAMARADES BR"}>
      <html lang={lang} />
      <meta http-equiv="content-language" content={lang} />
      {/* // location from props */}
      {!noRobots && (
        <meta name="robots" content="index, follow" />
      )}
      <meta name="keywords" content={seo.keywords} />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content={seo.author} />
      <meta
        name="copyright"
        content={`© ${new Date().getFullYear()} CAMARADES BR`}
      />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content={"CAMARADES BR"} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="CAMARADES BR" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.oneOf(["pt", "en"]),
  title: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  noRobots: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  lang: "pt",
  noRobots: false,
}

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        author
        keywords
        siteUrl
      }
    }
  }
`
