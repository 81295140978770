import React from "react"
import ChatBot from "react-simple-chatbot"
import ChatBotLinkBtn from "./link_button"
import avatar from '../../assets/images/chatbot/avatar.jpeg'

const Colaboration = () => (
  <div>
    <a
      href="mailto:camaradesbr@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Email 📧
    </a>{" "}
    <br />
    <a
      href="https://join.slack.com/t/camaradesbr/shared_invite/zt-12f82wifb-4F6Nx6rs2NLi2XuJzetPpw"
      target="_blank"
      rel="noopener noreferrer"
    >
      Slack Community 📝
    </a>
  </div>
)

const PartnersCamaradesBR = () => (
  <div>
    <a
      href="https://www.camarades.de"
      target="_blank"
      rel="noopener noreferrer"
    >
      CAMARADES Germany (Berlin)
    </a>{" "}
    <br />
    <a href="https://ed.ac.uk" target="_blank" rel="noopener noreferrer">
      CAMARADES UK (Edinburgh, Scotland)
    </a>
  </div>
)
const AllCamarades = () => (
  <div>
    <a
      href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/our-partners"
      target="_blank"
      rel="noopener noreferrer"
    >
      List of National Centers in CAMARADES UK (Edinburgh, Scotland)
    </a>
  </div>
)

const CamaradesHistory = () => (
  <div>
    <a
      href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/about-camarades"
      target="_blank"
      rel="noopener noreferrer"
    >
      History by CAMARADES UK (Edinburgh, Scotland)
    </a>
  </div>
)

export default function ChatBotEN() {
  return (
    <ChatBot
      hideSubmitButton
      placeholder=""
      botAvatar={avatar}
      floatingStyle={{ backgroundColor: '#07BCFF' }}
      bubbleStyle={{ backgroundColor: 'blue', maxWidth: '80%', color: 'white' }}
      bubbleOptionStyle={{ backgroundColor: '#008ECB', color: 'white' }}
      // bubbleStyle={{ backgroundColor: '#2364FF', maxWidth: '80%', color: 'white' }}
      // bubbleOptionStyle={{ backgroundColor: '#07BCFF', maxWidth: '80%', color: 'white' }}
      floating
      headerTitle="Sys - CAMARADES BR"
      steps={[
        {
          id: "index",
          message:
            "Hello, I am Sys and I will be your guide on the CAMARADES BR website. What subject would you like to know about? 😀",
          trigger: "index-options",
        },
        {
          id: "index-options",
          options: [
            {
              value: 1,
              label: "About CAMARADES",
              trigger: "about-camarades-options",
            },
            {
              value: 2,
              label: "About Systematic Review of Literature",
              trigger: "about-slr",
            },
          ],
        },
        {
          id: "about-slr",
          message: `A SRL is a methodology to locate, select, evaluate, and synthesize the evidence from the scientific literature providing data for meta-analysis in a SYSTEMATIC way. The SRL is used to answer a scientific question defined by pre-defined and pre-specified criteria in a TRANSPARENT way!`,
          trigger: "about-slr-options",
        },
        {
          id: "about-slr-options",
          options: [
            {
              value: 1,
              label: "Are there specific SRL tools for pharmacologists?",
              trigger: "slr-pharm-tools",
            },
            {
              value: 2,
              label:
                "If the field of study is not Pharmacology, are there generic SRL tools that could be used in non-clinical studies in other areas of the life sciences?",
              trigger: "slr-generic-tools",
            },
            {
              value: 3,
              label: "Are SRLs always accompanied by meta-analysis?",
              trigger: "slr-meta-analysis",
            },
            { value: "go-back", label: "Go back", trigger: "index-options" },
          ],
        },
        {
          id: "slr-pharm-tools",
          message: `Yea! Some tools can be adapted to perform in the context of Pharmacology. See here a tutorial that CAMARADES BR prepared for pharmacologists.`,
          trigger: "slr-pharm-tools-options",
        },
        {
          id: "slr-pharm-tools-options",
          options: [
            { value: 1, label: "SysPhar", trigger: "sysphar" },
            { value: "go-back", label: "Go back", trigger: "about-slr" },
          ],
        },
        {
          id: "sysphar",
          message: `SysPhar, from Systematic Pharmacology, is an initiative of CAMARADES BR to support SRL in the field of Pharmacology.`,
          trigger: "sysphar-options",
        },
        {
          id: "sysphar-options",
          options: [
            { value: 1, label: "Check out SysPhar", trigger: "go-to-sysphar" },
            { value: "go-back", label: "Go back", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-sysphar",
          component: <ChatBotLinkBtn to="/en/sysphar">SysPhar</ChatBotLinkBtn>,
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "slr-generic-tools",
          message: `Yea! There are tools to the assistant at your SRL in the context of non-clinical studies in general. See here a tutorial that CAMARADES BR translated to Portuguese from de website CAMARADES Berlin.`,
          trigger: "slr-generic-tools-options",
        },
        {
          id: "slr-generic-tools-options",
          options: [
            { value: 1, label: "Checkout Wiki", trigger: "go-to-wiki" },
            { value: "go-back", label: "Go back", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-wiki",
          component: <ChatBotLinkBtn to="/en/wiki">Wiki</ChatBotLinkBtn>,
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "slr-meta-analysis",
          message: `Not necessarily; your SRL may or may not be accompanied by a meta-analysis; it depends on your review question! See examples in our tutorials:`,
          trigger: "slr-meta-analysis-options",
        },
        {
          id: "slr-meta-analysis-options",
          options: [
            {
              value: 1,
              label: "Check examples of meta-analysis in SysPhar",
              trigger: "go-to-sysphar-meta-analysis",
            },
            {
              value: 2,
              label: "Check examples of meta-analysis on the Wiki",
              trigger: "go-to-wiki-meta-analysis",
            },
            { value: "go-back", label: "Go back", trigger: "about-slr" },
          ],
        },
        {
          id: "go-to-sysphar-meta-analysis",
          component: (
            <ChatBotLinkBtn to="/en/sysphar">
              Examples of meta-analysis in SysPhar
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-slr",
        },
        {
          id: "go-to-wiki-meta-analysis",
          component: (
            <ChatBotLinkBtn to="/en/wiki">
              Examples of meta-analysis on the Wiki
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-slr",
        },
        // START D2
        {
          id: "about-camarades-options",
          options: [
            {
              value: 1,
              label: "About CAMARADES in Brazil",
              trigger: "about-camarades-br",
            },
            {
              value: 2,
              label: "About CAMARADES in the wold",
              trigger: "about-camarades-world",
            },
            { value: "go-back", label: "Go back", trigger: "index-options" },
          ],
        },
        {
          id: "about-camarades-br",
          message: `CAMARADES is a non-profit organization resulting from collaboration between scientists to promote the systematic review of the literature (SRL) of non-clinical studies in the health area.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "about-camarades-br-options",
          options: [
            {
              value: 1,
              label: "Who supports?",
              trigger: "about-camarades-br-supporters",
            },
            {
              value: 2,
              label: "What is the collaboration vision?",
              trigger: "camarades-br-aim",
            },
            {
              value: 3,
              label: "What does this collaboration do?",
              trigger: "camarades-br-projects",
            },
            {
              value: 4,
              label: "What is the mission of CAMARADES BR?",
              trigger: "camarades-br-mission",
            },
            {
              value: 5,
              label: "I want to collaborate. How do I do?",
              trigger: "camarades-br-colab",
            },
            {
              value: "go-back",
              label: "Go back",
              trigger: "about-camarades-options",
            },
          ],
        },
        {
          id: "about-camarades-br-supporters",
          message: `CAMARADES BR projects have received support from public scientific funding agencies such as the Alexander von Humboldt Foundation, Capes, and CNPq.`,
          trigger: "go-to-camarades-br-supporters",
        },
        {
          id: "go-to-camarades-br-supporters",
          component: (
            <ChatBotLinkBtn to="/en/about-camarades">Supporters</ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-aim",
          message: `A world in which non-clinical studies have value, validity, and a positive impact on scientific development in healthcare.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-projects",
          message: `We have several projects in progress, from creating instructional material in Portuguese, available on this website, to the organization of training workshops, according to schedule availability.`,
          trigger: "go-to-camarades-br-projects",
        },
        {
          id: "go-to-camarades-br-projects",
          component: (
            <ChatBotLinkBtn to="/en/portfolio-and-initiatives">
              Projects in Progress
            </ChatBotLinkBtn>
          ),
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-mission",
          message: `To help promote, in the Brazilian scientific community, the benefits of RSL from non-clinical studies in life sciences.`,
          trigger: "about-camarades-br-options",
        },
        {
          id: "camarades-br-colab",
          message: `You can collaborate! Like? For example: 1-proposing a new SRL or participating in an existing one, 2-producing material for the site or helping to keep the Wiki up to date, 3-helping to organize a training workshop 4-participating in community discussions.
                    We look forward to your contact!`,
          trigger: "camarades-br-colab-options",
        },
        {
          id: "camarades-br-colab-options",
          message: `Tell us about your interests, contact us by e-mail or join our Slack community:`,
          trigger: "go-to-camarades-br-colab-options-btns",
        },
        {
          id: "go-to-camarades-br-colab-options-btns",
          component: <Colaboration />,
          delay: 2000,
          trigger: "about-camarades-br-options",
        },
        // START D3
        {
          id: "about-camarades-world",
          message: `The research group was created in 2004 by Malcolm Macleod and David Howells to promote systematic reviews and meta-analyses of non-clinical and pre-clinical studies in healthcare.`,
          trigger: "about-camarades-world-options",
        },
        {
          id: "about-camarades-world-options",
          options: [
            {
              value: 1,
              label: "What does the acronym of CAMARADES mean?",
              trigger: "camarades-initials",
            },
            {
              value: 2,
              label:
                "Besides Brazil, are there CAMARADES in other countries? Which one?",
              trigger: "other-camarades",
            },
            {
              value: 3,
              label:
                "How can I find out more about the history of CAMARADES outside Brazil?",
              trigger: "camarades-history",
            },
            {
              value: "go-back",
              label: "Go back",
              trigger: "about-camarades-options",
            },
          ],
        },
        {
          id: "camarades-initials",
          message: ` That is a great question! CAMARADES stands for: “Collaborative Approach to Meta-Analysis and Review of Animal Experimental Studies”.`,
          trigger: "about-camarades-world-options",
        },
        {
          id: "other-camarades",
          message:
            "Yes, there are CAMARADES in several countries! Our direct partnerships are in Germany (Berlin) and in the UK (Edinburgh, Scotland).",
          trigger: "go-to-partners-camarades-br",
        },
        {
          id: "go-to-partners-camarades-br",
          component: <PartnersCamaradesBR />,
          trigger: "all-camarades",
        },
        {
          id: "all-camarades",
          message: "See a list of national centres around the world.",
          trigger: "go-to-all-camarades",
        },
        {
          id: "go-to-all-camarades",
          component: <AllCamarades />,
          trigger: "about-camarades-world-options",
        },
        {
          id: "camarades-history",
          message: "Check out more about the history of CAMARADES here:",
          trigger: "go-to-camarades-history",
        },
        {
          id: "go-to-camarades-history",
          component: <CamaradesHistory />,
          trigger: "about-camarades-world-options",
        },
      ]}
    />
  )
}
