const ptArray = [
  { name: "Sobre a CAMARADES", path: "/sobre-a-camarades" },
  { name: "Portfólio & Iniciativas", path: "/portfolio-e-iniciativas" },
  { name: "Ferramentas & Recursos", path: "/ferramentas-e-recursos" },
  { name: "Wiki", path: "/wiki" },
  { name: "SysPhar", path: "/sysphar" },
  { name: "Guia SyRF", path: "/guia-syrf" },
  { name: "Blog", path: "/blog" },
  { name: "Contato", path: "/contato" },
]

const enArray = [
  { name: "About CAMARADES", path: "/en/about-camarades" },
  { name: "Portfolio & Initiatives", path: "/en/portfolio-and-initiatives" },
  { name: "Tools & Resources", path: "/en/tools-and-resources" },
  { name: "Wiki", path: "/en/wiki" },
  { name: "SysPhar", path: "/en/sysphar" },
  { name: "SyRF Guide", path: "/en/syrf-guide" },
  { name: "Blog", path: "/en/blog" },
  { name: "Contact", path: "/en/contact" },
]

export { ptArray, enArray }
