import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const texts = {
  pt: ["CAMARADES Brasil", "Todos os direitos reservados", "Licença Creative Commons", "Este obra está licenciado com uma Licença"],
  en: ["CAMARADES BR", "All rights reserved", "Creative Commons License", "This work is licensed under a Creative Commons Attribution 4.0 International License"],
}

function Footer({ language }) {
  const text = texts[language]
  return (
    <footer className="bg-dark text-white row d-flex justify-content-center align-items-center text-center">
      <p className="col-12">
        © {text[0]} {new Date().getFullYear()}. {text[1]}
      </p>
      <p className="col-12">
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
          <StaticImage
            src="../assets/images/licence-cc-4_0.png"
            alt={text[2]}
          />
        </a>
      </p>
      <p className="col-12">
        {text[3]} <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Atribuição 4.0 Internacional</a>.
      </p>
      {/* <p className="col-12">
        Designed by {" "} <a href="http://" target="_blank" rel="noopener noreferrer">Bruna Fernandes Braga</a>
        <br />
        Coded by {" "} <a href="http://vyk1.github.io" target="_blank" rel="noopener noreferrer">Victoria Botelho Martins</a>
      </p> */}
    </footer>
  )
}

Footer.propTypes = {
  language: PropTypes.oneOf(["pt", "en"]),
}

Footer.defaultProps = {
  language: "pt",
}

export default Footer
